var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"d-flex flex-column text-center"},[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-3 font-weight-bold mb-3 text-grey"},[_vm._v(" "+_vm._s(_vm.ModulesCourses.courseTitle)+" ")])]),_c('v-col',[_c('div',{staticClass:"py-1 px-5 mb-3 text-no-wrap rounded-pill secondary-color d-inline-block"},[_c('span',{staticClass:"display-1 font-weight-bold text-white"},[_vm._v(" "+_vm._s(_vm.$t("informationsTest"))+" ")])])]),_c('v-col',[_c('div',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.$t("selectTheModule"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l((_vm.ModulesCourses.modules),function(courseModule){return _c('v-col',{key:courseModule.id,staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('router-link',{attrs:{"to":{
              path: ("modules/" + (courseModule.id))
            }}},[_c('course-module',_vm._b({},'course-module',{
                title: courseModule.title,
                width: '265px',
                bold: true,
                textCenter: true
              },false))],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }