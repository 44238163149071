<template>
  <v-container>
    <v-row class="d-flex flex-column text-center">
      <v-col cols="12" class="mb-4">
        <h1 class="display-3 font-weight-bold mb-3 text-grey">
          {{ModulesCourses.courseTitle}}
        </h1>
      </v-col>
      <v-col>
        <div
          class="py-1 px-5 mb-3 text-no-wrap rounded-pill secondary-color d-inline-block"
        >
          <span class="display-1 font-weight-bold text-white">
            {{ $t("informationsTest") }}
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="text--disabled">
          {{ $t("selectTheModule") }}
        </div>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col
            v-for="courseModule in ModulesCourses.modules"
            :key="courseModule.id"
            cols="12"
            sm="6"
            md="4"
            class="d-flex justify-center align-center"
          >
            <router-link
              :to="{
                path: `modules/${courseModule.id}`
              }"
            >
              <course-module
                v-bind="{
                  title: courseModule.title,
                  width: '265px',
                  bold: true,
                  textCenter: true
                }"
              ></course-module
            ></router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CourseModule from "./Components/CourseModule";

export default {
  name: "SelectModuleToTest",

  components: {
    CourseModule
  },

  data() {
    return {};
  },

  created() {
    this.getCourseModules(this.$route.params.id);
  },

  methods: {
    ...mapActions("CourseArea", ["getCourseModules"])
  },

  computed: {
    ...mapState("CourseArea", ["ModulesCourses"])
  }
};
</script>
